export const POST_SELECTED_GLQ = "POST_SELECTED_GLQ";
export const POST_SELECTED_GLQ_SUCCESS = "POST_SELECTED_GLQ_SUCCESS";
export const POST_SELECTED_GLQ_FAILED = "POST_SELECTED_GLQ_FAILED";

export const POST_HISTORY_GLQ = "POST_HISTORY_GLQ";
export const POST_HISTORY_GLQ_SUCCESS = "POST_HISTORY_GLQ_SUCCESS";
export const POST_HISTORY_GLQ_FAILED = "POST_HISTORY_GLQ_FAILED";

export const POST_SELECTED_UNCL = "POST_SELECTED_UNCL";
export const POST_SELECTED_UNCL_SUCCESS = "POST_SELECTED_UNCL_SUCCESS";
export const POST_SELECTED_UNCL_FAILED = "POST_SELECTED_UNCL_FAILED";

export const POST_SELECTED_UNCX = "POST_SELECTED_UNCX";
export const POST_SELECTED_UNCX_SUCCESS = "POST_SELECTED_UNCX_SUCCESS";
export const POST_SELECTED_UNCX_FAILED = "POST_SELECTED_UNCX_FAILED";

export const POST_LIQUIDITY = "POST_LIQUIDITY";
export const POST_LIQUIDITY_SUCCESS = "POST_LIQUIDITY_SUCCESS";
export const POST_LIQUIDITY_FAILED = "POST_LIQUIDITY_FAILED";

export const POST_TOTAL_LIQUIDITY = "POST_TOTAL_LIQUIDITY";
export const POST_TOTAL_LIQUIDITY_SUCCESS = "POST_TOTAL_LIQUIDITY_SUCCESS";
export const POST_TOTAL_LIQUIDITY_FAILED = "POST_TOTAL_LIQUIDITY_FAILED";

export const POST_GLQ_TRADES = "POST_GLQ_TRADES";
export const POST_GLQ_TRADES_SUCCESS = "POST_GLQ_TRADES_SUCCESS";
export const POST_GLQ_TRADES_FAILED = "POST_GLQ_TRADES_FAILED";

export const POST_UNCX_TRADES = "POST_UNCX_TRADES";
export const POST_UNCX_TRADES_SUCCESS = "POST_UNCX_TRADES_SUCCESS";
export const POST_UNCX_TRADES_FAILED = "POST_UNCX_TRADES_FAILED";

export const POST_HISTORY_UNCX = "POST_HISTORY_UNCX";
export const POST_HISTORY_UNCX_SUCCESS = "POST_HISTORY_UNCX_SUCCESS";
export const POST_HISTORY_UNCX_FAILED = "POST_HISTORY_UNCX_FAILED";

export const POST_SELECTED_ETH_PRICE = "POST_SELECTED_ETH_PRICE";
export const POST_SELECTED_ETH_PRICE_SUCCESS =
  "POST_SELECTED_ETH_PRICE_SUCCESS";
export const POST_SELECTED_ETH_PRICE_FAILED = "POST_SELECTED_ETH_PRICE_FAILED";

export const POST_SELECTED_POLYGON = "POST_SELECTED_POLYGON";
export const POST_SELECTED_POLYGON_SUCCESS = "POST_SELECTED_POLYGON_SUCCESS";
export const POST_SELECTED_POLGON_FAILED = "POST_SELECTED_POLYGON_FAILED";
